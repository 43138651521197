import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Membership } from "@prisma/client"

import { RootState } from "../store"

export interface MembershipState {
  activeMembershipId: number
  activeOrganizationId: number
}

const initialState: MembershipState = {
  activeMembershipId: -1,
  activeOrganizationId: -1,
}

export const membershipsSlice = createSlice({
  name: "memberships",
  initialState,
  reducers: {
    setMembershipDetails: (state, action: PayloadAction<Membership>) => {
      const { id, organizationId } = action.payload

      state.activeMembershipId = id
      state.activeOrganizationId = organizationId
    },
  },
})

export const { setMembershipDetails } = membershipsSlice.actions

export const selectMembershipDetails = (state: RootState) => state.memberships
export const selectMembershipOrgId = (state: RootState) => state.memberships.activeOrganizationId
export const selectMembershipId = (state: RootState) => state.memberships.activeMembershipId

export default membershipsSlice.reducer
